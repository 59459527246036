require('bootstrap');
import  "../stylesheets/application.scss";

require("easy-autocomplete");

$(document).ready(function () {
    console.log("setting select from pack app root");
    $('.select2').select2({
        theme: "classic",
        placeholder: "Select an option"
    });
});